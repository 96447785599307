const aws = {
  region: 'eu-west-1',
  identityPoolId: 'eu-west-1:2eb4bd83-447c-493f-b1e4-1aab2df00390',
  userPoolId: 'eu-west-1_wtH7Gv5Al',
  userPoolClientId: 'm2iptolvn5budp3mm2iuo4up5',
  authDomain: 'test.auth.mes.condoor.com',
  mesDomain: 'test.mes.condoor.com',
  userManagementAppsyncEndpoint: 'https://test.auth.mes.condoor.com/graphql',
};
export default aws;
